import React, { Component } from 'react';
import './Tile.css';
export default class Announcement extends Component {
    
    tileClick(props) {
        props.gameLoop(props.loc,props.turn) ;
        
    }
    
    render(){
     
         return(
            <div className={"tile "+ this.props.loc} 
            
            onClick={
                !this.props.winner ?
                ()=>this.tileClick(this.props)
                : null
            }
             
            >
           <p>  {this.props.value} </p>
            </div>
         )
     }

}