import React, { Component } from 'react';
import './App.css';


export default class ResetButton extends Component {
     render(){
     
         return(
            <button onClick={this.props.reset} className="resetButton">
            
            Reset
            </button>
         )
     }

}