import React, { Component } from 'react';
import './Announcement.css';
export default class Announcement extends Component {
     render(){
        console.log("--+--",this.props);
        let winmessage="";
        if (this.props.winner==="o") {
            winmessage="Üzgünüm Kaybettiniz! :(";
        } else if(this.props.winner==="x") {
            winmessage="Kazandınız Tebrikler";
        } else {
            winmessage="Beraberlik";
        }

         return(
            <div className={this.props.winner ? 'visible' : 'hidden' }>
            <h2> 
            { winmessage  } 
            
            </h2>
            </div>
         )
     }

}
